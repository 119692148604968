import React from 'react'

import { css } from '@emotion/core'
import { constants  } from '../styles/colors'

const Footer = () => (
    <footer css={siteFooter} className="container">
        <p css={footerContent}>
            © Hiplyst {new Date().getFullYear()}. Tous droits réservés. All rights reserved.
            <br/>Production <a href="https://lyketil.com/fr/" target="_blank" rel="noopener noreferrer">Lyketil.com</a>
        </p>
    </footer>
)

export default Footer;

const siteFooter = css`
    width: 100%;
`
const footerContent = css`
    text-align: left;
    margin-top: 20px;
    padding-top: 20px;
    color: ${constants.darkgrey};
    font-size: 12px;
    line-height: 1.4;

    a {
        color: ${constants.accent};
        text-decoration: none;
    }
`