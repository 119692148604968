import React from 'react'
import PropTypes from 'prop-types'

import Nav from './nav'
import Footer from './footer'

import '../styles/app.scss'
import { css } from '@emotion/core'
import { constants } from '../styles/colors'

const Layout = ({ children }) => (
  <div css={background}>
    <Nav />
    
    <div css={wrapping}>
      {children}  
      <Footer />
    </div>

  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const background = css`
  background: ${constants.gradient2};
  margin: 0;
`

const wrapping = css`
  border-radius: ${constants.radiusLarge} ${constants.radiusLarge} 0 0;
  background-color: ${constants.whitegrey};
  padding: 40px 40px 0;
  margin: 50px auto 0;
  max-width: 1100px;
  box-shadow: ${constants.shadowBig};

  @media screen and (max-width: ${constants.breakpoint1}) {
    margin: 100px 0px 0;
    padding: 10px;
  }
`