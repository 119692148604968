export const constants = {
    black: '#000000',
    white: '#FFFFFF',
    accent: '#fc674e',
    accent2: '#6d53e1',
    darkgrey: '#15171A',
    midgrey: '#738a94',
    lightgrey: '#c5d2d9',
    whitegrey: '#f7f7fb',
    yellow: `#deab3e`,
    blue: `#3c5bd1`,
    purple: `#9354a4`,
    green: `#79c4cf`,
    red: `#FF0000`,
    font1: `'Dosis', sans-serif`,
    font2: `'Open Sans', sans-serif`,
    radiusMax: "100px",
    radiusLarge: "50px",
    radiusBig: "15px",
    radiusSmall: "5px",
    shadowBig: `0px 2px 18px 10px rgba(50,50,93,0.06)`,
    shadowSmall: `0px 2px 5px 0px rgba(50,50,93,0.2)`,
    breakpoint1: `800px`,
    gradient1: `linear-gradient(to right, #fd684f 0%, #f4634a 100%)`,
    gradient2: `linear-gradient(to top, #6d53e1 0%, #4e9dff 100%)`,
}