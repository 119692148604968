import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from "gatsby"

import { css } from '@emotion/core'
import { constants } from '../styles/colors'

const Nav = () => (
  <StaticQuery
    query={graphql`
      query SiteMetaQuery {
        site {
          siteMetadata {
            title
            facebook
            instagram
            twitter
          }
        }
      }
    `}

    render={ data => {
      const { title } = data.site.siteMetadata

      return(
        <div css={NavContainer}>
          <div css={NavWrapper}>
      
            <div css={NavPrimary}>   
              <Link to="/" css={Logo}><span>{title}.com</span></Link>
      
              {/*<nav>
                <ul>
                  <li><Link to="/lists">Lists</Link></li>
                  <li><Link to="/tags">Tag Explorer</Link></li>
                  <li><Link to="/curators">Curators</Link></li>
                  <li><Link to="/brands">Brands</Link></li>
                </ul>
              </nav>*/}
            </div>
      
            <div css={NavSecondary}>
            </div>
      
          </div>
        </div>
      )
    }}
  />
)

export default Nav

/**
 * 
 * CSS
 * 
 */


const NavContainer = css`
  width: 100%;
  z-index: 200;
`

const NavWrapper = css`
  margin: 0 20px;
  max-width: 960px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;

  @media (min-width: 960px){
    margin: 0 auto;
  }
`

// Nav Primary

const NavPrimary = css`
  display: flex;
  align-items: center;
  list-style: none;

  ul {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      text-transform: uppercase;
  
      a {
        padding: 10px 12px; 
        font-size: 0.7rem;
        text-decoration: none;
      }
    }
  }

`
const Logo = css`
  text-decoration: none;
  margin: 0 20px 0;
  padding: 0;
  display: block;
  height: 18px;

  span {
    font-size: 1.0rem;
    font-weight: 700;
    background-color: ${constants.white};
    color: ${constants.darkgrey};
    border-radius: ${constants.radiusMax};
    padding: 10px 20px;
  }
`

const NavSecondary = css`
  display: flex;
  align-items: center; 
`